import revive_payload_client_hoWvhcT9NY from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._2paqt5ghh6zfuyk5ig6vnn2voe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GAxjMfgHAa from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._2paqt5ghh6zfuyk5ig6vnn2voe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_UUVjT3rLJx from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._2paqt5ghh6zfuyk5ig6vnn2voe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_8psOXt6Uyl from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._2paqt5ghh6zfuyk5ig6vnn2voe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Z3ZqopAE6x from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._2paqt5ghh6zfuyk5ig6vnn2voe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_5O7RSqI4hu from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._2paqt5ghh6zfuyk5ig6vnn2voe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_WpDKAa5qUH from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._2paqt5ghh6zfuyk5ig6vnn2voe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/local/app/.nuxt/components.plugin.mjs";
import prefetch_client_tZsyCWqXiH from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._2paqt5ghh6zfuyk5ig6vnn2voe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UCeAz8jLwE from "/usr/local/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.19.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import documentDriven_2VbrLoJ5PR from "/usr/local/app/node_modules/.pnpm/@nuxt+content@2.13.2_ioredis@5.4.1_magicast@0.3.4_nuxt@3.12.4_@parcel+watcher@2.4.1_@types+no_gfatdc7ggrr2muc3ji6aqyplve/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import nuxt_plugin_rsRX5foaoG from "/usr/local/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.5_magicast@0.3.4_rollup@4.19.2/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import provideState_Jpgwdb76Nn from "/usr/local/app/node_modules/.pnpm/nuxt-graphql-middleware@4.0.0_@parcel+watcher@2.4.1_@types+node@22.0.2_graphql-tag@2.12.6_gra_3547hxsjm6mzp64x62xcs3fz2e/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.mjs";
import plugin_oCGTgF2jXn from "/usr/local/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.4_rollup@4.19.2/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_hvgEGFRjeJ from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.19.2_vue@3.4.35_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_zVRm8j0Vli from "/usr/local/app/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.19.2/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/usr/local/app/.nuxt/unocss.mjs";
import plugin_xDtleEcACi from "/usr/local/app/modules/gtm/runtime/plugin.ts";
import _0_graphqlConfig_yaUZblG674 from "/usr/local/app/plugins/0.graphqlConfig.ts";
import _1_auth_slaK5pfDbE from "/usr/local/app/plugins/1.auth.ts";
import _2_activeOrder_yYINElYs5H from "/usr/local/app/plugins/2.activeOrder.ts";
import collections_pRcEP3swaI from "/usr/local/app/plugins/collections.ts";
import foxentry_client_gcy3vwc779 from "/usr/local/app/plugins/foxentry.client.ts";
import scroll_client_HJUo2UKx9n from "/usr/local/app/plugins/scroll.client.ts";
import supportAgent_3y6YttNG9k from "/usr/local/app/plugins/supportAgent.ts";
import vee_validate_KcKlKmvCrJ from "/usr/local/app/plugins/vee-validate.ts";
export default [
  revive_payload_client_hoWvhcT9NY,
  unhead_GAxjMfgHAa,
  router_UUVjT3rLJx,
  payload_client_8psOXt6Uyl,
  navigation_repaint_client_Z3ZqopAE6x,
  check_outdated_build_client_5O7RSqI4hu,
  chunk_reload_client_WpDKAa5qUH,
  components_plugin_KR1HBZs4kY,
  prefetch_client_tZsyCWqXiH,
  plugin_client_UCeAz8jLwE,
  documentDriven_2VbrLoJ5PR,
  nuxt_plugin_rsRX5foaoG,
  provideState_Jpgwdb76Nn,
  plugin_oCGTgF2jXn,
  i18n_hvgEGFRjeJ,
  plugin_zVRm8j0Vli,
  unocss_MzCDxu9LMj,
  plugin_xDtleEcACi,
  _0_graphqlConfig_yaUZblG674,
  _1_auth_slaK5pfDbE,
  _2_activeOrder_yYINElYs5H,
  collections_pRcEP3swaI,
  foxentry_client_gcy3vwc779,
  scroll_client_HJUo2UKx9n,
  supportAgent_3y6YttNG9k,
  vee_validate_KcKlKmvCrJ
]